import { ProLayoutProps } from '@ant-design/pro-components';

/**
 * @name
 */
const Settings: ProLayoutProps & {
  pwa?: boolean;
  logo?: string;
} = {
  navTheme: 'realDark',
  colorPrimary: '#1890ff',
  layout: 'mix',
  contentWidth: 'Fixed',
  fixedHeader: true,
  fixSiderbar: true,
  pwa: true,
  logo: '/logo.svg',
  token: {},
  splitMenus: false,
  footerRender: false,
  colorWeak: false,
};

export default Settings;
