import { useState } from 'react';
import dayjs from "dayjs";

export default function useSearchForm() {
  const defaultStartDate = dayjs().utc(true).add(-30, 'days')
  const defaultEndDate = dayjs().utc(true).add(-1, 'days')
  const [
    search,
    setSearch
  ] = useState<API.Reports.SearchForm>({
    period: [
      defaultStartDate,
      defaultEndDate
    ],
    branch_id: undefined
  })
  const handleChangeSearch = (value: API.Reports.SearchForm) => {
    setSearch((prevSearch) => ({...prevSearch, ...value}))
  }

  const resetSearch = () => {
    setSearch({period: [defaultStartDate, defaultEndDate]})
  }

  return {
    search, resetSearch, handleChangeSearch
  };
}
