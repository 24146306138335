import { getMasterPlanning } from '@/services/api';
import { ProFormInstance } from '@ant-design/pro-components';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';

interface State {
  form?: ProFormInstance<API.OrderForm>;
  open: boolean;
  loading: boolean;

  orderId?: string;
  applianceId?: number;
  branchId?: number;
  masterId?: number;
  startTime?: Dayjs;
  endTime?: Dayjs;
  anyTime: boolean;
  lat?: number;
  lon?: number;

  date: Dayjs;
  selectedMaster?: API.MasterPlanning;
  masters: API.MasterPlanning[];
}

export default function useMasterPlanningModel() {
  const defaultState = {
    open: false,
    loading: false,
    date: dayjs(),
    masters: [],
    anyTime: false,
  };

  const [state, setState] = useState<State>(defaultState);

  const requestMasterPlanning = async () => {
    if (!state.applianceId) {
      return;
    }

    const geo = state.form?.getFieldsValue(['lat', 'lng']);

    setState({ ...state, loading: true });

    const { data } = await getMasterPlanning({
      date: state.date.format('DD-MM-YYYY'),
      appliance_id: state.applianceId,
      order_id: state.orderId,
      branch_id: state.branchId,
      ...(geo || {})
    });

    let masters: API.MasterPlanning[] = [];
    let selectedMaster: API.MasterPlanning | undefined = undefined;

    data.forEach((el) => {
      const master = {
        ...el,
        time_slots: el.time_slots.map((slot) => {
          return { ...slot, start_time: dayjs(slot.start_time), end_time: dayjs(slot.end_time) };
        }),
        any_time_slot: el.any_time_slot,
      };

      if (master.id === state.masterId) {
        selectedMaster = master;
      } else {
        masters.push(master);
      }
    });

    setState({ ...state, loading: false, masters, selectedMaster });
  };

  useEffect(() => {
    if (state.open && state.date && state.applianceId) {
      requestMasterPlanning().then();
    }
  }, [state.date, state.applianceId]);

  const changeDate = (date: Dayjs | null) => {
    if (false === date?.isSame(state.date, 'date')) {
      const newDate = date || dayjs();
      setState({ ...state, date: newDate });
    }
  };

  const changeTimeAndMaster = (
    startTime: Dayjs,
    endTime: Dayjs,
    masterId: number | undefined,
    anyTime = false,
  ) => {
    let newState: State = { ...state, masterId, startTime, endTime, anyTime };

    if (state.endTime && startTime.isSame(state.endTime, 'minutes')) {
      newState = { ...state, masterId, startTime: state.startTime, endTime, anyTime };
    } else if (state.startTime && state.startTime?.isSame(endTime, 'minutes')) {
      newState = { ...state, masterId, startTime, endTime: state.endTime, anyTime };
    }

    setState(newState);
  };

  const display = (form: ProFormInstance<API.OrderForm>) => {
    const {
      id: orderId,
      appliance_id: applianceId,
      master_id: masterId,
      start_time,
      end_time,
      any_time,
      branch_id: branchId
    } = form.getFieldsValue(true);

    const startTime = start_time && dayjs(start_time);
    const endTime = start_time && dayjs(end_time);
    const date = startTime || dayjs();

    setState({
      ...state,
      form,
      orderId,
      applianceId,
      masterId,
      date,
      startTime,
      endTime,
      anyTime: any_time,
      open: true,
      branchId
    });

    return state;
  };

  const reset = () => {
    setState(defaultState);
  };

  const apply = () => {
    state.form?.setFieldsValue({
      master: state.masters.find((m) => m.id === state.masterId) || state.selectedMaster,
      master_id: state.masterId,
      start_time: state.startTime?.format(),
      end_time: state.endTime?.format(),
      any_time: state.anyTime,
    });
    reset();
  };

  const close = () => {
    setState({ ...state, open: false });
  };

  return { display, apply, reset, close, changeDate, changeTimeAndMaster, ...state };
}
