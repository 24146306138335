import { useModel } from '@@/plugin-model';
import { PaginatedFormatReturn } from '@ahooksjs/use-request/es/types';
import { useState } from 'react';

declare type UseAcceptLeadsModelState = PaginatedFormatReturn<API.Lead> | null;

export default function useAcceptLeadsModel() {
  const { initialState } = useModel('@@initialState');

  const [state, setState] = useState<UseAcceptLeadsModelState>(null);

  const transformLead = (lead: API.Lead): API.Lead => {
    const userId = initialState?.currentUser?.id;
    const accepted = !!lead.accepted_by;
    const accepting = !accepted && userId === lead?.accepting_by?.id;
    const acceptable = (!accepted && !lead.accepting_at) || accepting;

    return {
      ...lead,
      acceptable,
      accepting,
      accepted,
    };
  };

  const init = (_state: PaginatedFormatReturn<API.Lead> | undefined) => {
    if (_state) {
      setState({
        ..._state,
        list: _state.list.map((_s) => transformLead(_s)),
      });
    } else {
      setState(null);
    }
  };

  const reset = () => {
    setState(null);
  };

  // When order updated
  //
  const onLeadUpdated = (lead?: API.Lead) => {
    if (!lead) {
      return null;
    }

    setState((_state) => {
      if (!_state) {
        return _state;
      }

      const _lead = transformLead(lead);
      const index = _state.list.findIndex(({ id }) => id === lead.id);
      if (index >= 0) {
        return {
          ..._state,
          list: Object.assign([], _state.list, { [index]: _lead }),
        };
      } else {
        return _state;
      }
    });
  };

  // When order created
  //
  const onLeadCreated = (lead: API.Lead) => {
    setState((_state) => {
      if (!_state || _state.current !== 1) {
        return _state;
      }
      const _lead = transformLead(lead);
      return { ..._state, list: [_lead, ..._state.list] };
    });
  };

  return { ...state, init, reset, onLeadCreated, onLeadUpdated };
}
