import { Result } from 'antd';
import React from 'react';

const NoAccessPage: React.FC = () => (
  <Result
    status="403"
    title="Нет доступа"
    subTitle="У вас нет разрешения на просмотр этой страницы. Обратитесь к администратору"
  />
);

export default NoAccessPage;
