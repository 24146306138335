import { PaginatedFormatReturn } from '@ahooksjs/use-request/es/types';
import { useState } from 'react';

export default function useOrdersIndex() {
  const defaultSearch = {
    text: undefined,
    filter: 'all'
  }

  const [
    reloadOrders,
    setReloadOrders
  ] = useState(0)
  const handleReloadOrders = () => {
    setReloadOrders((prev) => prev + 1)
  }

  const [
    search,
    setSearch
  ] = useState<API.OrderSearchForm>(defaultSearch)
  const handleChangeSearch = (value: API.OrderSearchForm) => {
    setSearch((prevSearch) => ({...prevSearch, ...value}))
  }

  const resetSearch = () => {
    setSearch(defaultSearch)
  }


  return {
    reloadOrders, setReloadOrders, handleReloadOrders,
    search, handleChangeSearch, resetSearch
  };
}
