// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/home/app/xrem24.com/releases/20240909203842/src/models/Reports/useSearchForm';
import model_3 from '/home/app/xrem24.com/releases/20240909203842/src/models/useMasterPlanningModel';
import model_4 from '/home/app/xrem24.com/releases/20240909203842/src/models/useOrdersIndex';
import model_5 from '/home/app/xrem24.com/releases/20240909203842/src/.umi-production/plugin-initialState/@@initialState';
import model_2 from '/home/app/xrem24.com/releases/20240909203842/src/models/useAcceptLeadsModel';

export const models = {
model_1: { namespace: 'Reports.useSearchForm', model: model_1 },
model_3: { namespace: 'useMasterPlanningModel', model: model_3 },
model_4: { namespace: 'useOrdersIndex', model: model_4 },
model_5: { namespace: '@@initialState', model: model_5 },
model_2: { namespace: 'useAcceptLeadsModel', model: model_2 },
} as const
