// @ts-ignore
/* eslint-disable */

import { PaginatedFormatReturn } from '@ahooksjs/use-request/es/types';
import { request } from '@umijs/max';

/** 获取当前的用户 GET /api/currentUser */
export async function currentUser(options?: { [key: string]: any }) {
  return request<{
    data: API.CurrentUser;
  }>('/api/get_current_user', {
    method: 'GET',
    ...(options || {}),
  });
}
export async function login(values: API.LoginParams) {
  return request<{ success: boolean }>('/api/login', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: { user: values },
  });
}

export async function getProfile() {
  return request<{ data: API.User }>('/api/profile', {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
}

export async function changePassword(values: API.User) {
  return request<{ data: API.User }>('/api/profile/change_password', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: {user: values}
  });
}

export async function changeProfile(values: API.User) {
  return request<{ data: API.User }>('/api/profile', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: {user: values}
  });
}

export async function masterFirstLogin(values: API.MasterFirstLoginParams, token: string) {
  return request<{ success: boolean }>('/api/masters/first_login', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Master-Token': token },
    data: { master: values, token: token },
  });
}

/** 退出登录接口 POST /api/login/outLogin */
export async function logout(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/logout', {
    method: 'DELETE',
    ...(options || {}),
  });
}

export async function getCustomer(id: string) {
  return request<{ data: API.Customer }>(`/api/customers/${id}`, {
    method: 'GET',
  });
}

export async function getCustomers(params?: {
  search?: {
    text?: string;
  };
  current?: number;
  pageSize?: number;
}) {
  return request<{ data: PaginatedFormatReturn<API.Customer> }>(`/api/customers`, {
    method: 'GET',
    params,
  });
}

export async function searchCustomers(params: { query: string }) {
  return request<{ data: API.Customer[] }>(`/api/customers/search`, {
    method: 'GET',
    params: params,
  });
}

export async function getHistories(params: {
  customer_id?: string;
  order_id?: string;
  lead_id?: string;
}) {
  return request<{ data: API.HistoryObjects }>(`/api/histories`, {
    method: 'GET',
    params: params,
  });
}

export async function updateCustomer(id: string, values: API.Customer) {
  return request<{ data: API.Customer }>(`/api/customers/${id}`, {
    method: 'PUT',
    data: { customer: values },
  });
}

export async function createCustomer(formData: API.Customer) {
  return request<{ data: API.Customer }>(`/api/customers`, {
    method: 'POST',
    data: { customer: formData },
  });
}

export async function getLeads(params?: { current?: number; pageSize?: number }) {
  return request<{ data: PaginatedFormatReturn<API.Lead> }>(`/api/leads`, {
    method: 'GET',
    params,
  });
}

export async function getLead(id: string) {
  return request<{ data: API.Lead }>(`/api/leads/${id}`, {
    method: 'GET',
  });
}

export async function updateLead(id: string, values: API.Lead) {
  return request<{ ok: boolean; error?: string }>(`/api/leads/${id}`, {
    method: 'PUT',
    data: { lead: values },
  });
}

export async function acceptLead(id: string) {
  return request<{ data: API.Lead }>(`/api/leads/${id}/accept`, {
    method: 'PUT',
  });
}

export async function createOrderComment(id: string, comment: API.OrderComment, lead_id?: string) {
  return request<{
    data: API.ResponseOrderUpdated;
  }>(`/api/orders/${id}/comment`, {
    method: 'POST',
    params: { lead_id },
    data: { comment },
  });
}

export async function updateStatusOrder(
  id: string,
  status: string,
  formData: API.RequestOrderUpdate,
  lead_id?: string,
) {
  return request<{
    success: boolean;
    errorMessage?: string;
    data: API.ResponseOrderUpdated;
  }>(`/api/orders/${id}/${status}`, {
    method: 'PUT',
    params: { lead_id },
    data: formData,
  });
}

export async function cancelOrder(id: string, reason: string, lead_id?: string) {
  return request<{
    success: boolean;
    errorMessage?: string;
    data: {
      lead?: API.Lead;
      order: API.OrderShow;
    };
  }>(`/api/orders/${id}/cancel`, {
    method: 'PUT',
    params: { lead_id },
    data: { reason },
  });
}

export async function complaintOrder(id: string, comment: API.OrderComment, lead_id?: string) {
  return request<{
    success: boolean;
    errorMessage?: string;
    data: {
      lead?: API.Lead;
      order: API.OrderShow;
    };
  }>(`/api/orders/${id}/complaint`, {
    method: 'POST',
    params: { lead_id },
    data: { comment },
  });
}

export async function warrantyOrder(id: string, comment: API.OrderComment, lead_id?: string) {
  return request<{
    success: boolean;
    errorMessage?: string;
    data: {
      lead?: API.Lead;
      order: API.OrderShow;
    };
  }>(`/api/orders/${id}/warranty`, {
    method: 'POST',
    params: { lead_id },
    data: { comment },
  });
}

export async function almostOrder(id: string, formData: API.Comment & API.OrderForm) {
  return request<{ success: boolean; data: { lead: API.Lead } }>(`/api/leads/${id}/almost_order`, {
    method: 'POST',
    data: { almost_order: formData },
  });
}

export async function wrongLead(id: string, formData: API.WrongLead) {
  return request<{ success: boolean; data: { lead: API.Lead } }>(`/api/leads/${id}/wrong_lead`, {
    method: 'POST',
    data: { wrong_lead: formData },
  });
}
export async function getOrders(params?: {
  search?: {
    customer_id?: string;
    text?: string;
    filter?: string;
    master_id?: number;
    status?: string|string[];
  };
  sort?: string;
  current?: number;
  pageSize?: number;
}) {
  return request<{ data: PaginatedFormatReturn<API.OrderShow> }>(`/api/orders`, {
    method: 'GET',
    params,
  });
}

export async function getOrder(id?: string) {
  return request<{ data: API.OrderShow }>(`/api/orders/${id}`, {
    method: 'GET',
  });
}

export async function updateOrder(id: string, values: API.OrderForm) {
  return request<{ ok: boolean; error?: string }>(`/api/orders/${id}`, {
    method: 'PUT',
    data: { order: values },
  });
}

export async function createOrder(values: API.OrderForm, leadId?: string) {
  return request<{
    success: boolean;
    data: { order: API.OrderShow; lead?: API.Lead };
  }>(`/api/orders`, {
    method: 'POST',
    params: { lead_id: leadId },
    data: { order: values },
  });
}

export async function changeOrderTime(id: string, values: API.OrderForm, lead_id?: string) {
  return request<{ data: API.ResponseOrderUpdated }>(`/api/orders/${id}/change_time`, {
    method: 'PUT',
    data: { order: values, lead_id },
  });
}

// export async function getMasters() {
//   return request<{ data: API.Master[] }>(`/api/masters`, {
//     method: 'GET',
//   });
// }

export async function getMasters(params?: {
  search?: {
    text?: string,
  };
  filter?: API.MasterFilterForm,
  current?: number;
  pageSize?: number;
}) {
  return request<{ data: PaginatedFormatReturn<API.Master> }>(`/api/masters`, {
    method: 'GET',
    params,
  });
}

export async function getMaster(masterId: number) {
  return request<{ data: API.Master }>(`/api/masters/${masterId}`, {
    method: 'GET',
  });
}

export async function getMasterForEdit(masterId: number) {
  return request<{ data: API.MasterForm }>(`/api/masters/${masterId}/edit`, {
    method: 'GET',
  });
}

export async function createMaster(values: API.MasterForm) {
  return request<{ data: API.Master }>(`/api/masters`, {
    method: 'POST',
    data: { master: values },
  });
}

export async function updateMaster(id: number, values: API.MasterForm) {
  return request<{ data: API.Master }>(`/api/masters/${id}`, {
    method: 'PUT',
    data: { master: values },
  });
}

export async function getMasterPlanning(
  params: {
    date: string,
    appliance_id: number,
    order_id?: string,
    branch_id?: number,
  },
  options?: { [key: string]: any },
) {
  return request<{ data: API.MasterPlanning[] }>(
    `/api/masters/planning`,
    {
      method: 'GET',
      params,
      ...options,
    },
  );
}

export async function getSchedules(entity_id: number, entity_type: string) {
  return request<{ data: API.Schedule[] }>(
    `/api/schedules`,
    {
      method: 'GET',
      params: { entity_id, entity_type }
    },
  );
}

export async function updateSchedule(id: number, data: API.Schedule) {
  return request<{ data: API.Schedule[] }>(
    `/api/schedules/${id}`,
    {
      method: 'PUT',
      data: { schedule: data },
    }
  );
}

export async function createSchedule(data: API.Schedule) {
  return request<{ data: API.Schedule[] }>(
    `/api/schedules`,
    {
      method: 'POST',
      data: { schedule: data },
    }
  );
}

export async function getWorkingTimes(params?: {
  master_id?: number;
  current?: number;
  pageSize?: number;
}) {
  return request<{ data: PaginatedFormatReturn<API.ScheduleCalendar> }>(`/api/working_times`, {
    method: 'GET',
    params,
  });
}

export async function saveWorkingTime(formData: API.ScheduleCalendarForm) {
  return request<{
    success: boolean;
    data: API.ScheduleCalendar;
  }>(`/api/working_times/save`, {
    method: 'POST',
    data: { working_time: formData },
  });
}

export async function getBranchesList(
  params?: {
    only_available?: boolean
  }
) {
  return request<{ data: API.Option.Item[] }>(`/api/lists/branches`, {
    method: 'GET',
    params
  });
}

export async function getAppliancesList() {
  return request<{ data: API.Option.Item[] }>(`/api/lists/appliances`, {
    method: 'GET',
  });
}

export async function getMasterRanksList() {
  return request<{ data: API.Option.RankItem[] }>(`/api/lists/master_ranks`, {
    method: 'GET',
  });
}

export async function getBrandRanksList() {
  return request<{ data: API.Option.RankItem[] }>(`/api/lists/brand_ranks`, {
    method: 'GET',
  });
}

export async function getBrandsList() {
  return request<{ data: API.Option.BrandItem[] }>(`/api/lists/brands`, {
    method: 'GET',
  });
}

export async function getSitesList() {
  return request<{ data: API.Option.Item[] }>(`/api/lists/sites`, {
    method: 'GET',
  });
}

export async function getPhoneGroupsList() {
  return request<{ data: API.Option.Item[] }>(`/api/lists/phone_groups`, {
    method: 'GET',
  });
}

export async function getLeadTypesList() {
  return request<{ data: API.Option.Item[] }>(`/api/lists/lead_types`, {
    method: 'GET',
  });
}

export async function getMastersList(params?: { branch_id?: number }) {
  return request<{ data: API.Option.Item[] }>(`/api/lists/masters`, {
    method: 'GET',
    params,
  });
}
