import { LogOutButton, UserName } from '@/components/TopRightContent';
import NoAccessPage from '@/pages/403';
import NoFoundPage from '@/pages/404';
import { currentUser as queryCurrentUser } from '@/services/api';
import {Settings as LayoutSettings, useBreakpoint} from '@ant-design/pro-components';
import type { RunTimeLayoutConfig } from '@umijs/max';
import { history } from '@umijs/max';
import {App, Spin, FloatButton} from 'antd';
import * as Sentry from "@sentry/react";
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';

import { setLocale } from 'umi';

import dayjs from 'dayjs';
import 'dayjs/locale/ru.js'
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import relativeTime from 'dayjs/plugin/relativeTime';
import minMax from 'dayjs/plugin/minMax';
import Utc from 'dayjs/plugin/utc';

import defaultSettings from '../config/defaultSettings';
import './app.css';
import { errorConfig } from './requestErrorConfig';
import {useEffect, useState} from "react";

const isProduction = process.env.NODE_ENV === 'production';
const noAccessPath = '/403';
const loginPath = '/user/login';
const newMasterPath = '/user/master/first-login';

dayjs.extend(isBetween);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isYesterday);
dayjs.extend(relativeTime);
dayjs.extend(minMax);
dayjs.extend(Utc);

Sentry.init({
  dsn: "https://888dcaecf6452a8d59db847238176dd7@o1100122.ingest.sentry.io/4506774070689792",
  enabled: process.env.NODE_ENV === 'production',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api\.xrem24\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: API.CurrentUser;
  loading?: boolean;
  fetchUserInfo?: () => Promise<API.CurrentUser | undefined>;
}> {
  // Если это не страница входа
  const { location } = history;

  const fetchUserInfo = async () => {
    try {
      const { data } = await queryCurrentUser({ skipErrorHandler: true });
      return data;
    } catch (error) {
      if (location.pathname !== loginPath) {
        history.push(loginPath);
      }
    }

    return undefined;
  };

  if (![loginPath, newMasterPath].includes(location.pathname)) {
    const currentUser = await fetchUserInfo();
    return {
      fetchUserInfo,
      currentUser,
      settings: defaultSettings as Partial<LayoutSettings>,
    };
  }

  return {
    fetchUserInfo,
    settings: defaultSettings as Partial<LayoutSettings>,
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState }) => {
  const [
    collapsed,
    setCollapsed
  ] = useState<boolean|undefined>(undefined);
  const screen = useBreakpoint();

  return {
    actionsRender: () => [<UserName key="username" />, <LogOutButton key="logOut" />],
    onPageChange: () => {
      if (['xs', 'sm', undefined].includes(screen)) {
        setCollapsed(true)
      }
      const { location } = history;
      // No Access Redirect
      if (!initialState?.currentUser && location.pathname !== noAccessPath) {
        history.push(loginPath);
      }
    },
    layoutBgImgList: [
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/D2LWSqNny4sAAAAAAAAAAAAAFl94AQBr',
        left: 85,
        bottom: 100,
        height: '303px',
      },
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/C2TWRpJpiC0AAAAAAAAAAAAAFl94AQBr',
        bottom: -68,
        right: -45,
        height: '303px',
      },
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/F6vSTbj8KpYAAAAAAAAAAAAAFl94AQBr',
        bottom: 0,
        left: 0,
        width: '331px',
      },
    ],
    links: [],
    menuHeaderRender: undefined,
    // 自定义 403 页面
    unAccessible: <NoAccessPage />,
    noFound: <NoFoundPage />,
    onCollapse: (val) => setCollapsed(val),
    collapsed,
    // 增加一个 loading 的状态
    childrenRender: (children) => {
      useEffect(() => {
        setLocale('ru-RU')
        dayjs.locale('ru')
      }, []);

      return (
        <CacheBuster
          currentVersion={packageInfo.version}
          isEnabled={isProduction} //If false, the library is disabled.
          loadingComponent={<Spin spinning />} //If not pass, nothing appears at the time of new version check.
        >
          <App>
            {children}
            <FloatButton.BackTop />
          </App>
        </CacheBuster>
      );
    },
    ...initialState?.settings,
  };
};

/**
 * @name request 配置，可以配置错误处理
 * 它基于 axios 和 ahooks 的 useRequest 提供了一套统一的网络请求和错误处理方案。
 * @doc https://umijs.org/docs/max/request#配置
 */

export const request = {
  ...errorConfig,
  requestInterceptors: [],
};
