import { logout } from '@/services/api';
import { history, useModel } from '@@/exports';
import { LogoutOutlined } from '@ant-design/icons';
import { stringify } from 'querystring';

export const UserName = () => {
  const { initialState } = useModel('@@initialState');
  const { currentUser } = initialState || {};
  return (
    <div
      style={{
        padding: '0 8px',
        fontSize: '14px',
      }}
    >
      {currentUser?.name}
    </div>
  );
};

export const LogOutButton = () => {
  const handleLogout = async () => {
    await logout();
    const { search, pathname } = window.location;
    const urlParams = new URL(window.location.href).searchParams;
    /** 此方法会跳转到 redirect 参数所在的位置 */
    const redirect = urlParams.get('redirect');
    // Note: There may be security issues, please note
    if (window.location.pathname !== '/user/login' && !redirect) {
      history.replace({
        pathname: '/user/login',
        search: stringify({
          redirect: pathname + search,
        }),
      });
    }
  };

  return (
    <div
      style={{
        padding: '0 8px',
        fontSize: '14px',
      }}
      onClick={handleLogout}
    >
      <LogoutOutlined />
    </div>
  );
};
